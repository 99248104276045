import React from 'react'
import { CommonText } from '../common/CommonText'

export class SingleProject extends React.Component {
  renderFacebook = () => {
    if (this.props.project.facebook_url) {
      return (
        <a href={this.props.project.facebook_url}>
          <i className="fab fa-facebook-f" />
        </a>
      )
    }
  }

  render = () => {
    return (
      <div id="SingleProject">
        <div className="img_container">
          <img
            src={require(`../../images/projects/${this.props.project.img}.jpg`)}
            alt={this.props.project.img_alt}
          />
          <div className="overlay" />
          <div className="overlay_text">
            <CommonText color="light">
              {this.props.project.done.toUpperCase()}
            </CommonText>
            <CommonText color="medium light second">
              {this.props.project.description}
            </CommonText>
          </div>
          <div className="description">
            <div>
              <CommonText color="light">{this.props.project.name}</CommonText>
            </div>
            <div className="icons">
              {this.renderFacebook()}
              <a href={this.props.project.url}>
                <i className="fas fa-link" />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
